
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("assets/sky.jpg");
  height: 75vh;
  align-content: center;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  opacity: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 60vh, 0 100%);

  .App-header-text {
    color: #ffffff;
    text-transform: uppercase;
    backface-visibility: hidden;
    margin-bottom: 60px;

    .App-header-text--main{
      display: block;
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: .2rem;
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      text-shadow: .1rem .1rem #16161a;
      margin-top: 20vh;
    }

    .App-header-text--sub{
      display: block;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .3rem;
      animation-name: moveInRight;
      animation-duration: 1s;
      animation-timing-function: ease-out;
    }
  }

  .btn,
  .btn:visited {
    background-image: none;
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .1s;
    position: relative;
    animation-name: moveInBottom;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
  }

  .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }

  .btn-white {
    background-color: rgba(255, 254, 250, .2);
    color: #f8f8f8;
    font-weight: 500;
    letter-spacing: .1rem;
    font-size: 1rem;
    text-shadow: .05rem .05rem #16161a;
  }

  .btn::after {
    background-color: rgba(255, 254, 250, .2);
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
  }

  .btn-white::after {
    opacity: 85%;
    background-color: rgba(156, 156, 156, 1);
  }

  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
  .btn-animated {
    animation: moveInBottom 1s ease-out .5s;
    animation-fill-mode: backwards;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
